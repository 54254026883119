import Image from 'next/image';
import styled from 'styled-components';

import sanityImage from '@root/utils/sanityImage';
import Link from 'next/link';

interface Props {
  video: SanityVideo;
  videoData?: YouTubeVideoListResponse['items'][0];
}

const Wrap = styled.div`
`;

const Thumb = styled.div`
  border-radius: ${props => props.theme.helpers.toRem(10)};
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: top;
  }
`;

const Title = styled.div`
  margin-top: ${props => props.theme.helpers.toRem(23)};

  ${Wrap}:hover & {
    h3 {
      color: ${props => props.theme.colors.main};
    }
  }

  h3 {
    margin: 0;
    ${props => props.theme.helpers.fontSize(25)}
    font-weight: 400;
    color: ${props => props.theme.colors.secondaryAlt};
    transition: all 0.2s;
  }
`;

const Video = ({ video, videoData }: Props): JSX.Element | null => {
  if (!videoData) return null;

  return (
    <Wrap>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
            {
              "@context": "https://schema.org",
              "@type": "VideoObject",
              "name": "${videoData.snippet.title}",
              "description": "${videoData.snippet.description}",
              "thumbnailUrl": "${videoData.snippet.thumbnails.high.url}",
              "uploadDate": "${videoData.snippet.publishedAt}",
              "contentUrl": "https://youtu.be/${videoData.id}"
            }
          `
        }} />

      <Link href={video.videoUrl} target="_blank" rel="noopener noreferrer">
        {videoData.snippet.thumbnails.medium && <Thumb>
          <Image
            width={380}
            height={214}
            src={videoData.snippet.thumbnails.medium.url}
            alt={`Imagem do vídeo "${videoData.snippet.title}"`} />
        </Thumb>}

        <Title>
          <h3>{videoData.snippet.title}</h3>
        </Title>
      </Link>
    </Wrap>
  );
};

export default Video;
