import { useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { groq } from 'next-sanity';

import Section, { SectionContent, SectionHeader } from '@/components/Section';
import { Container } from '@/components/Layout';

import client from '@root/client';

import GalleryCard from './GalleryCard';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Props {
  section: SanityHomeGallery;
  galleries?: SanityGallery[];
  count?: number;
}

const Wrap = styled(Section)<Props>`
  margin-top: ${props => props.theme.helpers.toRem(118)};
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    width: 99999px;
    height: ${props => props.theme.helpers.toRem(1283)};
    margin-right: ${props => props.theme.helpers.toRem(-517)};
    position: absolute;
    top: ${props => props.theme.helpers.toRem(-180)};
    right: 100%;
    border-radius: ${props => props.theme.helpers.toRem(30)};
    background: ${props => rgba(props.theme.colors.mainDark, 0.7)};
    z-index: -1;

    @media screen and (max-width: 640px) {
      margin-right: 0;
      right: 50%;
    }
  }

  ${SectionHeader} {
    margin-bottom: ${props => props.theme.helpers.toRem(-95)};

    @media screen and (max-width: 1200px) {
      margin-bottom: 40px;
    }
  }
`;

const GalleryList = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: minmax(0, auto);
  grid-column-gap: ${props => props.theme.helpers.toRem(16)};
  grid-row-gap: ${props => props.theme.helpers.toRem(32)};

  &:not(:first-child) {
    margin-top: ${props => props.theme.helpers.toRem(64)};
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const LoadMoreButton = styled.button`
  margin: ${props => props.theme.helpers.toRem(100)} auto 0;
  padding: ${props => props.theme.helpers.toRem(12)} ${props => props.theme.helpers.toRem(30)};
  display: flex;
  align-items: center;
  border: 0;
  border-radius: ${props => props.theme.helpers.toRem(10)};
  font-family: var(--font-agenda);
  font-weight: 600;
  ${props => props.theme.helpers.fontSize(25)}
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.mainDark};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.theme.colors.main};
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 100px;
  }
`;

const Gallery = ({ section, count, ...props }: Props): JSX.Element | null => {
  const [page, setPage] = useState(1);
  const [galleries, setGalleries] = useState(props.galleries)

  if (!galleries?.length || section?.disabled) return null;

  const pages = count! / 5;

  const handleLoadMoreClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setPage(page => page += 1);

    const fetch = await client.fetch(groq`{
      "galleries": *[_type == "gallery" && !(_id in path("drafts.**"))] | order(date desc)[($lastIndex + 1)...($lastIndex + 6)],
    }`, { lastIndex: galleries.length - 1, page });

    setGalleries(galleries => [...galleries!, ...fetch.galleries]);
  };

  const chunk = (array: Array<any>, size: number) => {
    const chunks = [];
    array = [].concat(...array);

    while (array.length) {
      chunks.push(
        array.splice(0, size)
      )
    }

    return chunks;
  };

  const galleriesGroups = chunk(galleries, 5);

  return (
    <Wrap
      id="fotos"
      section={section}
      data-aos="fade">

      <Container>
        <SectionHeader>
          <h2 data-aos="fade-down" data-aos-delay="200">{section?.title || 'fotos'}</h2>
        </SectionHeader>

        <SectionContent data-aos="fade-up" data-aos-delay="200">
          {galleriesGroups.map((galleries, index) => <GalleryList key={`gallery-list-${index}`} className={index % 2 === 0 ? 'regular' : 'inverted'}>
            {galleries.map(gallery => <GalleryCard key={gallery._id} gallery={gallery} />)}
          </GalleryList>)}

          {page < pages && <LoadMoreButton onClick={handleLoadMoreClick}>ver mais</LoadMoreButton>}
        </SectionContent>
      </Container>
    </Wrap>
  );
};

export default Gallery;
