import { useEffect, useState } from 'react';
import type { GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import styled from 'styled-components';
import { groq } from 'next-sanity';

import Layout from '@/components/Layout';

import Header from '@/components/pages/index/Header';
import Events from '@/components/pages/index/Events';
import Posts from '@/components/pages/index/Posts';
import Videos from '@/components/pages/index/Videos';
import Discography from '@/components/pages/index/Discography';
import Gallery from '@/components/pages/index/Gallery';
import Fans from '@/components/pages/index/Fans';
import Contact from '@/components/pages/index/Contact';

import client from '@root/client';

interface Props {
  config: SanitySiteConfig;
  navigation: SanityNavigation[];
  homePage: SanityHomePage;
  events: SanityEvent[];
  posts: SanityBlogPost[];
  videos: SanityVideo[];
  videosWithData: YouTubeVideoListResponse;
  records: SanityRecord[];
  galleries: SanityGallery[];
  galleriesCount: number;
}

const Content = styled.div``;

const HomePage: NextPage<Props> = ({
  config,
  navigation,
  homePage,
  events,
  posts,
  videos,
  videosWithData,
  records,
  galleries,
  galleriesCount
}: Props) => {
  const [documentLoaded, setDocumentLoaded] = useState(false);

  useEffect(() => {
    setDocumentLoaded(true);
  }, []);

  return (
    <Layout
      noHeader={true}
      navigation={navigation}>

      <Head>
        <title>{`${config.title || 'Léo Magalhães'}`}</title>
        <meta name="description" content={config.description || 'Coragem, determinação, talento e carisma, elementos que se encaixaram na vida de um garoto chamando Léo Magalhães.'} />

        <meta property="og:title" content={config.title || 'Léo Magalhães'} key="og-title" />
        <meta property="og:description" content={config.description || 'Coragem, determinação, talento e carisma, elementos que se encaixaram na vida de um garoto chamando Léo Magalhães.'} key="og-description" />
      </Head>

      <Header
        config={config}
        navigation={navigation}
        section={homePage.header} />

      <Content>
        {documentLoaded && <Events
          section={homePage.events}
          events={events} />}

        <Posts
          section={homePage.posts}
          highlights={homePage.posts?.posts}
          posts={posts} />

        <Videos
          config={config}
          section={homePage.videos}
          highlights={homePage.videos?.videos}
          videos={videos}
          videosWithData={videosWithData} />

        <Discography
          section={homePage.discography}
          records={records} />

        <Gallery
          section={homePage.gallery}
          galleries={galleries}
          count={galleriesCount} />

        <Fans
          section={homePage.fans} />

        <Contact
          config={config}
          section={homePage.contact} />
      </Content>
    </Layout>
  )
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const pageFetch = await client.fetch(groq`{
    "config": *[_type == "config" && _id == 'config'][0],
    "navigation": *[_type == "navigation" && !(_id in path("drafts.**"))] | order(orderRank){
      _id,
      title,
      external,
      externalUrl,
      blank,
      internalPage->
    },
    "homePage": *[_type == "homePage" && _id == 'homePage'][0]{
      header,
      events,
      posts{
        disabled,
        posts[]->
      },
      videos{
        disabled,
        videos[]->
      },
      discography,
      gallery,
      fans,
      contact
    },
    "events": *[_type == "event" && !(_id in path("drafts.**")) && date >= $currentDate] | order(date asc),
    "posts": *[_type == "blogPost" && !(_id in path("drafts.**"))] | order(date desc),
    "videos": *[_type == "video" && !(_id in path("drafts.**"))] | order(date desc),
    "records": *[_type == "record" && !(_id in path("drafts.**"))] | order(releaseDate desc),
    "galleries": *[_type == "gallery" && !(_id in path("drafts.**"))] | order(date desc)[0...5],
    "galleriesCount": count(*[_type == "gallery" && !(_id in path("drafts.**"))]),
  }`, { currentDate: new Date().toISOString().replace(/T.*/, '') });

  const config: SanitySiteConfig = pageFetch.config;
  const navigation: SanityNavigation[] = pageFetch.navigation;
  const homePage: SanityHomePage = pageFetch.homePage;
  const events: SanityEvent[] = pageFetch.events;
  const posts: SanityBlogPost[] = pageFetch.posts;
  const videos: SanityVideo[] = pageFetch.videos;
  const records: SanityRecord[] = pageFetch.records;
  const galleries: SanityGallery[] = pageFetch.galleries;
  const galleriesCount: number = pageFetch.galleriesCount;

  const videoIds: string[] = [];

  videos.forEach(video => {
    const videoIdRegex = /(?:https:\/\/)(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=)?([A-z0-9-_]*)/;
    const videoURLPieces = video.videoUrl.match(videoIdRegex);
    const videoId = videoURLPieces?.[1] || null;

    if (!videoId) return;

    videoIds.push(videoId);
  });

  const videosData = await fetch(`https://www.googleapis.com/youtube/v3/videos/?id=${videoIds.join('&id=')}&part=snippet&part=statistics&part=player&key=AIzaSyCAt-GpkSHjOO-No5xmJHGBr3TMbIOJ_f8&maxResults=1000`);
  const videosWithData: YouTubeVideoListResponse = await videosData.json();

  return {
    props: {
      config,
      navigation,
      homePage,
      events,
      posts,
      videos,
      videosWithData,
      records,
      galleries,
      galleriesCount,
    },
    revalidate: 60
  }
};

export default HomePage;
